<div class="center-object mt-3 animate__animated animate__fadeIn animate__delay-2s">

    <ng-container *transloco="let t; read: 'header'">

        <a class="main-menu" [routerLink]="['/about']">01. {{ t('about') }}</a>

        <a class="main-menu" [routerLink]="['/skills']">02. {{ t('skills') }}</a>

        <a class="main-menu" [routerLink]="['/labs']">03. {{ t('labs') }}</a>

        <a class="main-menu" [routerLink]="['/blog']">04. {{ t('blog') }}</a>

        <a class="main-menu" [routerLink]="['/contact']">05. {{ t('contact') }}</a>
    </ng-container>

</div>