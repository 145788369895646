<app-header></app-header>
<div class="crt">
    <ng-container *transloco="let t; read: 'labs'">
        <div class="animate__animated animate__fadeIn container mt-5">
            <div class="nes-container with-title is-dark mt-5 ">
                <p class="title title-color">{{ t('title') }}</p>
                <div class="d-grid gap-2">
                    <a style="width:100%" [routerLink]="['/labs/crypto-data']" class="nes-badge">
                        <span class="is-success text-uppercase">{{ t('labs1') }}</span>
                    </a>
                    <a style="background-color: #6c757d;" style="width:100%" [routerLink]="['/labs/image-classifier']" class="nes-badge">
                        <span class="is-success text-uppercase">{{ t('labs2') }}</span>
                    </a>
                </div>
            </div>
        </div>
    </ng-container>
</div>