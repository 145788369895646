<nav class="navbar navbar-expand-lg navbar-light bg-light nes-container is-rounded" style="border-image-repeat: unset;">
    <ng-container *transloco="let t; read: 'header'">
        <div class="container-fluid">
            <a class="navbar-brand" [routerLink]="['']"><img src="/assets/images/dan.png" class="logo-icon" alt="Daniel Gonzalez"></a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
            <div class="collapse navbar-collapse" id="navbarText">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0" style="font-size:18px;">
                    <li class="nav-item">
                        <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="['/about']">{{ t('about') }}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="['/skills']">{{ t('skills') }}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="['/labs']">{{ t('labs') }}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="['/blog']">{{ t('blog') }}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="['/contact']">{{ t('contact') }}</a>
                    </li>
                </ul>
                <div id="divWeatherMain" class="animate__animated animate__fadeInDown animate__delay-1s">
                    <div class="row" style="width: 100%">
                        <div class="col-5">
                            <div class="weatherWidgetRow" style="font-size: 20px;margin-top: 5px;">
                                {{WeatherData.temp_celcius}}°</div>
                            <div class="weatherWidgetRow" style="font-size: 10px;">{{WeatherData.temp_min}}° / {{WeatherData.temp_max}}°
                            </div>
                            <div class="weatherWidgetRow" style="font-size: 10px;">{{ t('temp1') }}: {{WeatherData.temp_feels_like}}°
                            </div>
                        </div>
                        <div class="col-7">
                            <div class="weatherWidgetRow city">{{WeatherData.name}}
                            </div>
                            <div class="weatherWidgetRow" style="font-size: 12px;">{{ t('temp2') }}: {{WeatherData.main.humidity}}%
                            </div>
                        </div>
                    </div>
                </div>
                <span class="navbar-text">
                <section class="icon-list">
                    <!-- linkedin -->
                    <a class="d-inline" href="https://www.linkedin.com/in/mdgs/" target="_blank"><i class="nes-icon linkedin"></i></a>
                    <!-- instagram -->
                    <a class="d-inline" href="https://www.instagram.com/codifik/" target="_blank"><i class="nes-icon instagram"></i></a>
                    <!-- github -->
                    <a class="d-inline" href="https://github.com/Dangzz" target="_blank"><i class="nes-icon github"></i></a>
                    <ng-container *ngIf="siteLanguage==='English'">
                     <button type="button" class="nes-btn btn-sm btn-lang mt-2"
                            (click)="changeSiteLanguage('es')">Español</button>
                       
                    </ng-container>
                    <ng-container *ngIf="siteLanguage==='Español'">
                        <button type="button" class="nes-btn btn-sm btn-lang mt-2"
                            (click)="changeSiteLanguage('en')">English</button>
                    </ng-container>
                </section>
            </span>
            </div>
        </div>
    </ng-container>
</nav>