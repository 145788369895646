<div id='hideMe' class='overlay text-center'>
    <div>
        <h5 class='mt-5'>CHECK SYSTEM</h5>
        <p class='sysfont'>PLATFORM...........{{platform | uppercase}}</p>
        <p class='sysfont'>CPU CORES..........{{cores }}</p>
        <p class='sysfont'>COOKIE ENABLED.....{{cookiee | uppercase}}</p>
        <p class='sysfont'>LOAD TIME..........{{connectTime}}ms</p>
        <p class='mt-5'>{{ua | uppercase}}</p>
    </div>
</div>
<div class="text-center crt">

    <div class="animate__animated animate__zoomIn animate__slow animate__delay-3s">
        <img src="/assets/images/logo.png" class="img-fluid parpadea" alt="Mario Daniel Gonzalez UI DEV">
    </div>
    <div class="hero-run"><img src="/assets/images/walker.gif" /></div>
    <app-menu></app-menu>
</div>
