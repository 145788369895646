import { Component, OnInit } from '@angular/core';
import { DataCryptoService } from '../data-crypto.service';

@Component({
  selector: 'app-cryptodata',
  templateUrl: './cryptodata.component.html',
  styleUrls: ['./cryptodata.component.scss']
})

export class CryptodataComponent implements OnInit {

  objectKeys = Object.keys;
  cryptos: any;
  articles: any;
  loading: boolean;

  // tslint:disable-next-line: variable-name
  constructor(private _data: DataCryptoService) { }

  ngOnInit(): void {

    this.loading = true;

    this._data.getPrices()
      .subscribe(res => {
        this.cryptos = res;
        console.log(res);
      });

    this._data.getArticles()
      .subscribe((res: any) => {
        this.articles = res.Data;
        console.log(this.articles);
        this.loading = false;
      });

  }

}
