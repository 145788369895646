<app-header></app-header>
<div class="crt">
    <div class="d-flex justify-content-center align-items-center flex-column">
        <video autoplay playsinline muted id="webcam" width="400" height="400" #video></video>

        <div *ngIf="loading">
            <img src="">
            <p>Loading...</p>
        </div>

        <div class="list-group">
            <div class="list-group-item" *ngFor="let prediction of predictions">
                {{prediction.className}} - {{prediction.probability}}
            </div>
        </div>
    </div>
</div>