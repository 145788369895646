import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { BlogComponent } from './blog/blog.component';
import { ContactComponent } from './contact/contact.component';
import { CryptodataComponent } from './cryptodata/cryptodata.component';
import { IntroComponent } from './intro/intro.component';
import { LabsComponent } from './labs/labs.component';
import { SkillsComponent } from './skills/skills.component';
import { NgxDeviceDetectorComponent } from './blog/ngx-device-detector/ngx-device-detector.component';
import { ImageClassifierComponent } from './image-classifier/image-classifier.component';

const routes: Routes = [
  { path: '', component: IntroComponent },
  { path: 'about', component: AboutComponent, pathMatch: 'full' },
  { path: 'contact', component: ContactComponent, pathMatch: 'full' },
  { path: 'skills', component: SkillsComponent, pathMatch: 'full' },
  { path: 'labs', component: LabsComponent, pathMatch: 'full' },
  { path: 'blog', component: BlogComponent, pathMatch: 'full' },
  { path: 'blog/device-detector', component: NgxDeviceDetectorComponent, pathMatch: 'full' },
  { path: 'labs/crypto-data', component: CryptodataComponent, pathMatch: 'full' },
  { path: 'labs/image-classifier', component: ImageClassifierComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

