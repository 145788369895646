<app-header></app-header>
<div class="crt">
    <ng-container *transloco="let t; read: 'aboutme'">
        <div class="animate__animated animate__fadeIn">
            <div class="nes-container with-title is-centered is-dark mt-5">
                <p class="title title-color">{{ t('title') }}</p>
                <p>{{ t('about1') }}<br /><br />{{ t('about2') }}</p>
            </div>
        </div>
        <div class="animate__animated animate__fadeIn animate__delay-1s">
            <div class="nes-container with-title is-dark mt-5">
                <p class="title title-color">{{ t('title2') }}</p>


                <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    </div>
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <div class="row">
                                <div class="col">
                                    <div class="nes-container is-dark with-title" style="min-height: 360px;">
                                        <p class="title nes-text title-blue">
                                            Kwik Dollar LLC - Dinex
                                        </p>
                                        <div class="lists is-dark">
                                            <ul class="nes-list is-circle">
                                                <li>
                                                    {{ t('experience1') }}
                                                </li>
                                                <li>
                                                    {{ t('experience2') }}
                                                </li>
                                                <li>
                                                    {{ t('experience3') }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="nes-container is-dark with-title" style="min-height: 360px;">
                                        <p class="title title-blue">izzi Telecom</p>
                                        <ul class="nes-list is-circle">
                                            <li>{{ t('experience4') }}</li>
                                            <li>{{ t('experience5') }}</li>
                                            <li>{{ t('experience6') }}</li>
                                            <li>{{ t('experience7') }}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="row animate__animated animate__fadeIn">
                                <div class="col">
                                    <div class="nes-container is-dark with-title" style="min-height: 360px;">
                                        <p class="title title-blue">Multimedios Redes</p>
                                        <ul class="nes-list is-circle">
                                            <li>
                                                {{ t('experience8') }}
                                            </li>

                                            <li>
                                                {{ t('experience9') }}
                                            </li>

                                            <li>
                                                {{ t('experience10') }}
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="nes-container is-dark with-title" style="min-height: 360px;">
                                        <p class="title title-blue">ilinium S.A de C.V.</p>
                                        <ul class="nes-list is-circle">
                                            <li>{{ t('experience11') }}</li>
                                            <li>{{ t('experience12') }}</li>
                                            <li>{{ t('experience13') }}</li>
                                            <li>{{ t('experience14') }}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="carousel-control-prev nes-btn is-error scroll-btn" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev" style="left: -32px;">
                    <span class="" aria-hidden="true">&lt;</span>
                    <span class="visually-hidden">&lt;</span>
                </button>
                    <button class="carousel-control-next nes-btn is-error scroll-btn" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next" style="right: -32px;">
                    <span class="" aria-hidden="true">&gt;</span>
                    <span class="visually-hidden">&gt;</span>
                </button>

                </div>
            </div>
        </div>
    </ng-container>
</div>