<app-header></app-header>

<div class="crt">
    <div class="container">
        <div class="row mt-4">
            <h3>ngx-device-detector</h3>
            <p class="mt-4">An Angular 5+ powered AOT compatible device detector that helps to identify browser, os and other useful information regarding the device using the app. The processing is based on user-agent.</p>
            <p class="mt-4"><i>Use CLI install this library</i></p>
            <code>
                $ npm install ngx-device-detector --save
            </code>
            <div class="text-center">

                <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img src="/assets/images/01.jpg" class="d-block w-100" alt="Device Detector">
                        </div>
                        <div class="carousel-item">
                            <img src="/assets/images/02.jpg" class="d-block w-100" alt="Device Detector">
                        </div>
                        <div class="carousel-item">
                            <img src="/assets/images/03.jpg" class="d-block w-100" alt="Device Detector">
                        </div>
                        <div class="carousel-item">
                            <img src="/assets/images/04.jpg" class="d-block w-100" alt="Device Detector">
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>

            </div>
        </div>
    </div>
</div>