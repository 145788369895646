<app-header></app-header>
<app-loading *ngIf="loading"></app-loading>
<div class="crt">
    <div *ngIf="cryptos" class="nes-table-responsive cdata">
        <table style="width: 100%;" class="nes-table table table-dark table-striped mt-4">
            <thead>
                <tr>
                    <th style="color:#75ef73">COIN</th>
                    <th style="color:#75ef73">USD</th>
                    <th style="color:#75ef73">EUR</th>
                    <th style="color:#75ef73">MXN</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let crypto of objectKeys(cryptos)">
                    <td>{{ crypto }}</td>
                    <td>$ {{ cryptos[crypto].USD}}</td>
                    <td>€ {{ cryptos[crypto].EUR}}</td>
                    <td>$ {{ cryptos[crypto].MXN}}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="text-center">
        <div *ngFor="let article of articles" class="card text-white bg-dark mb-3" style="max-width: 25rem; padding: 20px;">
            <div class="card-header">
                <a href='{{ article.guid }}' target="_blank"> <img style="width:100%" src='{{ article.imageurl }}' alt='{{ article.tags }}'></a>
            </div>
            <div class="card-body">
                <p style="min-height: 200px;" class="card-text">{{ article.title }}</p>
                <span><a style="width:100%" href='{{ article.guid }}' target="_blank" class="nes-badge">
                        <span class="is-success">See more</span>
                </a>
                </span>
            </div>
        </div>
    </div>

</div>