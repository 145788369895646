<!-- Footer -->
<footer class="text-center text-white animate__animated animate__fadeIn animate__delay-2s">
    <div class="mt-4 crt">
        <ng-container *transloco="let t; read: 'footer'">
            <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.2);">
                <span> © {{ currentYear }} Mario Daniel Gonzalez / codifik</span><br/>
                <span class="fot2"> {{ t('footer1') }} <i class="nes-icon heart" style="margin-bottom: 8px;"></i> | NES.css<i class="nes-logo"></i>  {{ t('footer2') }} <img class="angular-logo" src="/assets/images/angular-icon.png" alt="Angular"><b style="padding-left:3px;">NGULAR</b></span>
                <div class="mt-5">

                </div>
            </div>

        </ng-container>
        <!-- Copyright -->
    </div>
    <!-- Copyright -->
</footer>
<!-- Footer -->
