import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  siteLanguage = 'English';

  languageList = [
    { code: 'en', label: 'English' },
    { code: 'es', label: 'Español' }
  ];

  // tslint:disable-next-line: typedef-whitespace
  WeatherData : any;
  GeoData: any;



  constructor(private service: TranslocoService) { }


   changeSiteLanguage(language: string): void {
    this.service.setActiveLang(language);
    this.siteLanguage = this.languageList.find(f => f.code === language).label;
  }


  // tslint:disable-next-line: typedef
  async ngOnInit() {

    this.WeatherData = {
      main : {},
      isDay: true
    };

    // console.log(datosDeUbicacion);
    this.getWeatherData();

  }


  getWeatherData(): any{
    fetch(`https://api.openweathermap.org/data/2.5/weather?lat=25.67507&lon=-100.31847&appid=6c512ec67b08af16eadf4aef5082ea73`)
      .then(response => response.json())
      .then(data => {
        this.setWeatherData(data);
        console.log(data);
      });
  }


  setWeatherData(data): any{
    this.WeatherData = data;
    const sunsetTime = new Date(this.WeatherData.sys.sunset * 1000);
    this.WeatherData.sunset_time = sunsetTime.toLocaleTimeString();
    const currentDate = new Date();
    this.WeatherData.isDay = (currentDate.getTime() < sunsetTime.getTime());
    this.WeatherData.temp_celcius = (this.WeatherData.main.temp - 273.15).toFixed(0);
    this.WeatherData.temp_min = (this.WeatherData.main.temp_min - 273.15).toFixed(0);
    this.WeatherData.temp_max = (this.WeatherData.main.temp_max - 273.15).toFixed(0);
    this.WeatherData.temp_feels_like = (this.WeatherData.main.feels_like - 273.15).toFixed(0);
  }

}
