<app-header></app-header>
<div class="crt">
    <ng-container *transloco="let t; read: 'contact'">
        <div class="animate__animated animate__fadeIn">
            <div class="nes-container with-title is-centered is-dark mt-5 ">
                <p class="title title-color">{{ t('title') }}</p>
                <form (ngSubmit)="onSubmit(contactForm)" #contactForm="ngForm">
                    <input type="text" placeholder="{{ t('contact1') }}" name="name" ngModel required #name="ngModel">
                    <input type="text" placeholder="{{ t('contact2') }}" email name="email" ngModel required #email="ngModel">
                    <textarea placeholder="{{ t('contact3') }}" name="messages" ngModel required #messages="ngModel"></textarea>
                    <input type="submit" class="btn btn-secondary" value="{{ t('contact4') }}">
                </form>
            </div>
        </div>
    </ng-container>
</div>