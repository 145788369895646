<app-header></app-header>
<div class="crt">
    <div class="container animate__animated animate__fadeIn">
        <div class="row">
            <div>
                <section class="nes-container is-dark mt-3">
                    <section class="message-right text-center">
                        <!-- Balloon -->
                        <h4 style="text-align:left">Angular 6+ Device Detector</h4>
                        <div class="nes-balloon from-right is-dark">
                            <p>Simple User Agent using Angular Ngx Device Detector</p>
                            <a class="btn btn-secondary" [routerLink]="['/blog/device-detector']" type="button">See more</a>
                        </div>
                        <div style="display: contents;"><img style="height:110px" src="/assets/images/dan-2.png" alt="Daniel Gonzalez"></div>

                    </section>
                </section>
                <!--   <section class="nes-container is-dark mt-3">
                    <section class="message-right text-center">

                        <h4 style="text-align:left">Hello</h4>
                        <div class="nes-balloon from-right is-dark">
                            <p>Good morning. Thou hast had a good night's sleep, I hope.</p>
                        </div>
                        <div style="display: contents;"><img style="height:110px" src="/assets/images/dan-2.png" alt="Daniel Gonzalez"></div>
                    </section>
                </section> -->
            </div>
        </div>
    </div>
</div>