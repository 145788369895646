import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class DataCryptoService {

  result: any;
  resultArticles: any;


  // tslint:disable-next-line: variable-name
  constructor(private _http: HttpClient) { }

  // tslint:disable-next-line: typedef
  getPrices() {
    return this._http.get('https://min-api.cryptocompare.com/data/pricemulti?fsyms=BTC,ETH,DOGE,LTC,XLM,EOS,SHIB&tsyms=USD,EUR,MXN')
      .pipe(map(result => this.result = result));
  }



  // tslint:disable-next-line: typedef
  getArticles() {
    return this._http.get('https://min-api.cryptocompare.com/data/v2/news/?lang=ES')
      .pipe(map(result => this.resultArticles = result));
  }

}
