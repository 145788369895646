<app-header></app-header>
<div class="crt">
    <ng-container *transloco="let t; read: 'skills'">
        <div class="animate__animated animate__fadeIn">
            <div class="nes-container with-title is-dark mt-5 ">
                <p class="title title-color">{{ t('title') }}</p>
                 <div class="bar front expert nes-progress" data-skill="UI / UX DESIGN"></div>
                <div class="bar back intermediate nes-progress" data-skill="PHP"></div>
                <div class="bar back intermediate nes-progress" data-skill="ANGULAR 6+"></div>
                <div class="bar back intermediate nes-progress" data-skill="TYPESCRIPT"></div>
                <div class="bar front advanced nes-progress" data-skill="JAVASCRIPT"></div>
                <div class="bar front expert nes-progress" data-skill="BOOTSTRAP 5"></div>
                <div class="bar front expert nes-progress" data-skill="SEO"></div>
                <div class="bar front expert nes-progress" data-skill="HTML5 & CSS3"></div>
            </div>
        </div>
    </ng-container>
</div>
