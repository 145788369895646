import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy  } from '@angular/common';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IntroComponent } from './intro/intro.component';
import { MenuComponent } from './menu/menu.component';
import { FooterComponent } from './footer/footer.component';
import { AboutComponent } from './about/about.component';
import { HeaderComponent } from './header/header.component';
import { ContactComponent } from './contact/contact.component';
import { FormsModule } from '@angular/forms';
import { SkillsComponent } from './skills/skills.component';
import { DataCryptoService } from './data-crypto.service';
import { CryptodataComponent } from './cryptodata/cryptodata.component';
import { LoadingComponent } from './loading/loading.component';
import { BlogComponent } from './blog/blog.component';
import { LabsComponent } from './labs/labs.component';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { NgxDeviceDetectorComponent } from './blog/ngx-device-detector/ngx-device-detector.component';
import { ImageClassifierComponent } from './image-classifier/image-classifier.component';

@NgModule({
  declarations: [
    AppComponent,
    IntroComponent,
    MenuComponent,
    FooterComponent,
    AboutComponent,
    HeaderComponent,
    ContactComponent,
    SkillsComponent,
    CryptodataComponent,
    LoadingComponent,
    BlogComponent,
    LabsComponent,
    NgxDeviceDetectorComponent,
    ImageClassifierComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    TranslocoRootModule
  ],
  providers: [DataCryptoService, {provide : LocationStrategy , useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
